import React, { useState, useRef, useEffect } from "react";

import { Table, Space, Button, Input, Tooltip } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import api from "../../Config/Api";

import {
  SearchOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { getDFCList } from "../../Action/Death";

const mapState = (props) => ({
  DFCList: props?.DeathReducer?.dfcList,

  Death: props?.DeathReducer?.dfcListSuccess,

  SelectLoader: props?.DeathReducer?.isloading,
});

const mapDispatch = (dispatch) => {
  return {
    GetDFCList: () => dispatch(getDFCList()),
  };
};

function DfcList(store) {
  // console.log("store: ", store);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  let [DFCArray, setDFCArray] = useState([]);
  // console.log("DFCArray: ", DFCArray);
  const [pdfloader, setpdfloader] = useState(false);

  useEffect(() => {
    store.GetDFCList();
  }, []);

  useEffect(() => {
    if (store?.Death) {
      // console.log("store: ", store);
      setDFCArray(store?.DFCList);
    }
  }, [store?.Death]);

  function handleMemberStatus(checked, _id) {
    // console.log("checked: ", checked);
    let statusaction = checked ? "PENDING" : "ACTIVE";
    const payload = {
      _id,
      status: statusaction,
    };
    store?.MemberStatus(payload);
  }
  const generatepdfAPI = async (dfcNumber) => {
    // console.log("dfcNumber", dfcNumber);
    // setpdfloader(true);
    try {
      const response = await api.post(
        "/admin/downloadPdf",
        { DFCNumber: dfcNumber },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Create a blob object from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });
      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement("a");
      // Set the href attribute of the link to the URL
      link.href = url;
      // Set the download attribute of the link to the desired filename
      link.download = `DFC_${dfcNumber}.pdf`;
      // Append the link to the document body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link from the document body
      document.body.removeChild(link);
      // Revoke the URL to release memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error occurred while downloading the PDF file:", error);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true, closeDropdown: true });
    setSearchText("");
  };

  let dataSource =
    DFCArray &&
    DFCArray?.map((data, index) => ({
      key: index,
      id: data?._id,
      dfcNumber: data?.dfcNumber,
      payableTotalAmount: data?.payableTotalAmount,
      numberOfUsers: data?.numberOfUsers,
      status: data?.status,
      createdDate:
        moment(new Date(data?.createdDate)).format("DD/MM/YYYY") || "NA",
      //   informhdate:
      //     moment(new Date(data?.InformOnUTC)).format("DD/MM/YYYY") || "NA",
      //   payableamount: data?.payableamount.toLocaleString("en-IN", {
      //     maximumFractionDigits: 0,
      //     style: "currency",
      //     currency: "INR",
      //   }),
    }));

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 112,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 112,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "DFC No",
      dataIndex: "dfcNumber",
      key: "dfcNumber",
      ...getColumnSearchProps("dfcNumber", "DFC No"),
    },

    {
      title: "Payable Total Amount",
      dataIndex: "payableTotalAmount",
      key: "payableTotalAmount",
      ...getColumnSearchProps("payableTotalAmount", "Payable Total Amount"),
    },
    {
      title: "Number Of Users",
      dataIndex: "numberOfUsers",
      key: "numberOfUsers",
      ...getColumnSearchProps("numberOfUsers", "Number Of Users"),
    },
    {
      title: "Create Date",
      dataIndex: "createdDate",
      key: "createdDate",
      ...getColumnSearchProps("createdDate", "Create Date"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Link to={`/dfc/${record?.id}`}>
              <Button type="primary" icon={<EyeOutlined />} />
            </Link>
          </Tooltip>
          
          {record.status !== "PENDING" && (
            <Tooltip title="Download">
              <Link to="">
                <Button
                  style={{ background: "black", border: "black" }}
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => generatepdfAPI(record.dfcNumber)}
                />
              </Link>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <SiteLayout>
      {store?.SelectLoader === true && <Loader />}
      {pdfloader === true && <Loader />}

      <div
        style={{ padding: "30px" }}
        className="checkout-form-container gig-sure-card-wrapper"
      >
        <div className="wrapper_twoDiv">
          <Space style={{ marginBottom: 18 }}>
            <h3 className="start">DFC List</h3>
          </Space>
        </div>

        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(DfcList);
