import React, { useState, useEffect } from "react";
import { Table, message, Space, Button, DatePicker } from "antd";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../Reusable/Layout/SiteLayout";
import { TransactionListAction } from "../../Action/Transaction";
import "../Members/userstyle.scss";

const { RangePicker } = DatePicker;

const Report = () => {
  const TransactionList = useSelector(
    (state) => state?.TransactionReducer?.TransactionList
  );
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      await dispatch(TransactionListAction());
      setLoading(false);
    };
    fetchTransactions();
  }, [dispatch]);

  const handleFilter = () => {
    if (selectedDates?.length === 2) {
      const [startDate, endDate] = selectedDates;
      const start = moment(startDate).startOf("day");
      const end = moment(endDate).endOf("day");

      const filtered = TransactionList.filter((txn) => {
        const txnDate = moment(txn?.paymentDate);
        return txnDate.isBetween(start, end, null, "[]");
      });

      setFilteredData(filtered);
    } else {
      message.warning("Please select a start and end date.");
    }
  };

  const handleDownloadFullReport = () => {
    if (!filteredData.length) {
      message.warning("No data to generate a report.");
      return;
    }
  
    setPdfLoader(true);
    const doc = new jsPDF({ orientation: "landscape", unit: "mm", format: "a4" });
  
    // Add Title
    doc.setFontSize(18);
    doc.text("Transaction Report", 148, 15, { align: "center" });
  
    // Add Date Range
    if (selectedDates.length === 2) {
      const [startDate, endDate] = selectedDates;
      const formattedRange = `(${moment(startDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`;
      doc.setFontSize(12);
      doc.text(formattedRange, 148, 25, { align: "center" });
    }
  
    const headers = [
      { content: "Payment Method", styles: { halign: "center" } },
      { content: "Receipt No.", styles: { halign: "center" } },
      { content: "Payment Date", styles: { halign: "center" } },
      { content: "Status", styles: { halign: "center" } },
      { content: "Late Fees", styles: { halign: "center" } },
      { content: "Total Amount", styles: { halign: "center" } },
    ];
  
    const body = filteredData.map((row) => [
      row.paymentMethod || "N/A",
      row.receiptNumber,
      moment(row.paymentDate).format("DD MMM YYYY"),
      row.status || "N/A",
      row.lateFees?.toFixed(2) || "0.00",
      row.amount.toFixed(2),
    ]);
  
    // Add Summary Row with "Total" Label
    body.push([
      { content: "Total", colSpan: 4, styles: { halign: "right", fontStyle: "bold" } },
      { content: totalLateFees.toFixed(2), styles: { halign: "right", fontStyle: "bold" } },
      { content: totalAmount.toFixed(2), styles: { halign: "right", fontStyle: "bold" } },
    ]);
  
    doc.autoTable({
      startY: 30,
      head: [headers],
      body: body,
      theme: "grid",
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontSize: 10,
      },
      bodyStyles: { fontSize: 9, cellPadding: 3 },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
        3: { cellWidth: 30 },
        4: { cellWidth: 30, halign: "right" },
        5: { cellWidth: 40, halign: "right" },
      },
      footStyles: { fillColor: [211, 211, 211], fontStyle: "bold" },
      tableWidth: "auto",
    });
  
    const pageHeight = doc.internal.pageSize.height;
    doc.setFontSize(10);
    doc.text("Generated on " + moment().format("DD MMM YYYY, h:mm A"), 14, pageHeight - 10);
  
    doc.save(`Transaction_Report_${moment().format("YYYYMMDD_HHmmss")}.pdf`);
    setPdfLoader(false);
  };
  
  

  const totalAmount = filteredData.reduce((sum, record) => sum + (record.amount || 0), 0);
  const totalLateFees = filteredData.reduce((sum, record) => sum + (record.lateFees || 0), 0);

  const columns = [
    { title: "Payment Method", dataIndex: "paymentMethod", key: "paymentMethod" },
    { title: "Receipt No.", dataIndex: "receiptNumber", key: "receiptNumber" },
    { title: "Payment Date", dataIndex: "paymentDate", key: "paymentDate", render: (date) => moment(date).format("DD MMM YYYY") },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Late Fees", dataIndex: "lateFees", key: "lateFees", render: (lateFees) => lateFees?.toFixed(2) || "0.00" },
    { title: "Total Amount", dataIndex: "amount", key: "amount", render: (amount) => amount?.toFixed(2) },
  ];

  return (
    <SiteLayout>
      {loading && <Loader />}
      <div style={{ padding: "30px" }} className="checkout-form-container gig-sure-card-wrapper">
        <div className="wrapper_twoDiv">
          <Space style={{ marginBottom: 18, display: "flex", justifyContent: "space-between" }}>
            <h3 className="start">Report List</h3>
            <Space>
              <RangePicker onChange={(dates) => setSelectedDates(dates)} style={{ marginRight: 8 }} />
              <Button type="primary" onClick={handleFilter}>
                Filter
              </Button>
              <Button type="primary" onClick={handleDownloadFullReport}>
                Download Report
              </Button>
            </Space>
          </Space>
          <Table
            dataSource={filteredData.map((item, index) => ({ ...item, key: index }))}
            columns={columns}
            pagination={false}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4} style={{ textAlign: "right", fontWeight: "bold" }}>
                  Total:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} style={{ fontWeight: "bold" }}>
                  {totalLateFees.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} style={{ fontWeight: "bold" }}>
                  {totalAmount.toFixed(2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
          {pdfLoader && <Loader message="Generating PDF..." />}
        </div>
      </div>
    </SiteLayout>
  );
};

export default Report;