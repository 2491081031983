import {
  FIND_MEMBER,
  FIND_MEMBER_NULL,
  GET_DEATH_MEMBERS,
  GET_DFC_LIST,
  GET_DFC_DETAIL,
  FIND_DEATH,
  LOADER,
  INITIALIZE_STATE,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

// death member find
export const findMemberId = (payload) => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      // console.log("Action payload------> ", payload);
      api.post("admin/users/find", payload).then(
        (response) => {
          if (response?.status === 200) {
            //message.success(response?.data?.message);
            dispatch({
              type: FIND_MEMBER,
              payload: response.data.message,
            });
          } else {
            // console.log(response.data.message, "action----------");
            message.error(response?.data?.message);
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
            dispatch({
              type: FIND_MEMBER_NULL,
            });
          }
        }
      );
    } catch (err) {
      console.log("catch err", err);
    }
  };
};

export const initializeState = () => {
  return (dispatch) => {
    dispatch({
      type: INITIALIZE_STATE,
    });
  };
};

export const getDeathList = () => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      // console.log("Action payload------> ", payload);
      api.post("admin/users/death/list").then(
        (response) => {
          if (response?.status === 200) {
            //message.success(response?.data?.message);
            dispatch({
              type: GET_DEATH_MEMBERS,
              payload: response.data.message,
            });
          } else {
            // console.log(response.data.message, "action----------");
            message.error(response?.data?.message);
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
            // dispatch({
            //     type: FIND_MEMBER_NULL,
            // });
          }
        }
      );
    } catch (err) {
      console.log("catch err", err);
    }
  };
};

export const getDFCList = () => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      // console.log("Action payload------> ", payload);
      api.get("admin/users/dfc/list").then(
        (response) => {
          // console.log("response: ", response);
          if (response?.status === 200) {
            //message.success(response?.data?.message);
            dispatch({
              type: GET_DFC_LIST,
              payload: response.data.message,
            });
          } else {
            // console.log(response.data.message, "action----------");
            message.error(response?.data?.message);
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
            // dispatch({
            //     type: FIND_MEMBER_NULL,
            // });
          }
        }
      );
    } catch (err) {
      console.log("catch err", err);
    }
  };
};
export const getDFCDetail = (id) => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      api.get(`admin/users/dfc/list?dfcId=${id}`).then(
        (response) => {
          console.log("response: ", response);
          if (response?.status === 200) {
            dispatch({
              type: GET_DFC_DETAIL,
              payload: response.data.message,
            });
            dispatch(loader(false));
          } else {
            message.error(response?.data?.message);
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
          }
        }
      );
    } catch (err) {
      console.log("catch err", err);
    }
  };
};

export const fetchdeathmember = (payload) => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      api.post("admin/users/death/find", payload).then(
        (response) => {
          if (response?.status === 200) {
            //message.success(response?.data?.message);
            dispatch({
              type: FIND_DEATH,
              payload: response.data.message,
            });
          } else {
            // console.log(response.data.message, "action----------");
            message.error(response?.data?.message);
          }
        },
        function (error) {
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
          }
        }
      );
    } catch (err) {
      console.log("catch err", err);
    }
  };
};

export const loader = (para) => {
  return {
    type: LOADER,
    payload: para,
  };
};
