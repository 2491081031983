import React, { useEffect, useState, useRef } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Members/style.css";
import api from "../../Config/Api";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  message,
  PageHeader,
  Skeleton,
  Radio,
  DatePicker,
} from "antd";
import moment from "moment";

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
const { Title } = Typography;
// Action

const mapDispatch = (dispatch) => {
  return {
    TransactioEdit: (payload) => dispatch(TransactioEdit(payload)),
  };
};

const mapState = (props) => ({
  SelectLoader: props?.MemberReducer?.isloading,
  success: props?.MemberReducer?.success,
  successDetails: props?.MemberReducer?.successDetails,
  MemberDetails: props?.MemberReducer?.MemberDetails,
});

function TransactioEdit(store) {
  const formRef = useRef();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const dfcdata = useSelector((state) => state.FeesReducer);

  useEffect(() => {
    if (id) {
      store?.SingalMemberDetail(id);
    }
  }, [id, dfcdata]);

  useEffect(() => {
    form.setFieldsValue({
      PaymentMethod: "CASH",
    });
  }, []);

  useEffect(() => {
    if (store?.success && store?.successDetails?.data?.message) {
      form.resetFields();
      store.resetState();
    }
  }, [store?.success && store?.successDetails?.data?.message]);

  const [members, setMembers] = useState([]);
  const txnId = useParams().txnId;
  const [loading, setLoading] = useState(false);
  const [dfcDetails, setDfcDetails] = useState(null);
  const [dfcNumber, setDfcNumber] = useState("");
  const [groupData, setGroupData] = useState("");
  const [userId, setUserId] = useState("");
  const [memberDetails, setMemberDetails] = useState([]);

  const [combinedTotal, setCombinedTotal] = useState("");
  const [totalFees, setTotalFees] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [cashDetails, setCashDetails] = useState({
    receiptNo: "",
    receiptDate: null, // Set default value to current date
  });

  const [chequeDetails, setChequeDetails] = useState({
    bankName: "",
    branchName: "",
    chequeNo: "",
    chequeDate: null,
    bankCharges: 0,
    chequeReturnCharge: 0,
    receiptDate: null,
  });

  const handleSubmit = async () => {
    if (!dfcNumber) {
      message.error("DFC number is required");
      return;
    }

    if (!/^\d+$/.test(dfcNumber)) {
      message.error("Please insert a valid DFC number");
      return;
    }

    setLoading(true);

    try {
      const response = await api.get(
        `admin/fetchDfcDetails?dfcNumber=${dfcNumber}`
      );

      if (response.status === 200) {
        setDfcDetails(response.data.data);
        message.success("Data fetched successfully");
      } else {
        message.error(response.data.message || "Failed to fetch details");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Please insert valid Dfc No."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (index, fieldType) => (e) => {
    const value = parseFloat(e.target.value) || 0;

    setMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        [fieldType]: value,
      };

      console.log("Updated Members:", updatedMembers);

      updateTotals(updatedMembers, chequeDetails);
      return updatedMembers;
    });
  };

  useEffect(() => {
    updateTotals(members, chequeDetails);
  }, [members, chequeDetails]); // Ensure recalculation when members or cheque details change

  const updateTotals = (updatedMembers, updatedChequeDetails) => {
    let newTotalFees = 0;
    let newLateFees = 0;

    // Parse values for bank charges and cheque return charges
    const newBankCharges = parseFloat(updatedChequeDetails?.bankCharges || 0);
    const newChequeReturnCharges = parseFloat(
      updatedChequeDetails?.chequeReturnCharge || 0
    );

    // Calculate total fees and late fees
    updatedMembers.forEach(({ fees, lateFees }) => {
      const fixedFees = 300; // Fixed fee per member
      newTotalFees += fixedFees; // Accumulate total fees
      newLateFees += parseFloat(lateFees) || 0; // Accumulate late fees
    });

    // Calculate the combined total (fees + late fees + charges)
    const combinedTotal =
      newTotalFees + newLateFees + newBankCharges + newChequeReturnCharges;

    // Logging the combined total for debugging
    console.log("Setting Combined Total to:", combinedTotal);

    // Set the calculated values to the corresponding states
    setTotalFees(newTotalFees); // Set total fees
    setTotalCharges(newLateFees + newBankCharges + newChequeReturnCharges); // Set total charges
    setCombinedTotal(combinedTotal); // Set the combined total

    // Force re-render to ensure UI updates (optional)
    setForceUpdate((prev) => !prev);
  };

  const handleChequeChange = (e) => {
    const { name, value } = e.target;
    const updatedDetails = { ...chequeDetails, [name]: value };
    setChequeDetails(updatedDetails);

    // Recalculate combined total correctly with updated charges
    const membersTotal = members.reduce(
      (sum, member) => sum + (member.fees || 0) + (member.lateFees || 0),
      0
    );

    // Calculate the new combined total
    const updatedTotal = membersTotal;
    setCombinedTotal(updatedTotal);
  };

  useEffect(() => {
    const total = members.reduce((acc, member) => {
      const fees = parseFloat(member.fees) || 0;
      const lateFees = parseFloat(member.lateFees) || 0;
      return (
        acc +
        fees +
        lateFees +
        chequeDetails.bankCharges +
        chequeDetails.chequeReturnCharge
      );
    }, 0);

    setCombinedTotal(total); // Update combined total
  }, [members]);

  function handleFormSubmit1(params) {
    // pass payload to action and reducer
    let payload = {
      memberdetails: [
        {
          memberId1: params?.MemberId1,
          fees1: params?.Fees1,
          charges1: params?.Charges1,
        },
        {
          memberId2: params?.MemberId2,
          fees2: params?.Fees2,
          charges2: params?.Charges2,
        },
      ],
      payment: params?.PaymentMethod,
      receiptno: params?.ReceiptNo,
      receiptdate: params?.ReceiptDate,
    };

    if (params?.Group) {
      payload["group"] = params?.Group;
    }

    if (params?.FirstMember) {
      payload["firstmember"] = params?.FirstMember;
    }

    if (params?.PaymentMethod === "CHEQUE") {
      payload["chequedetail"] = {
        bank: params?.BankName,
        branch: params?.BranchName,
        cheque: params?.ChequeNo,
        chequedate: params?.ChequeDate,
      };
    }
    store.addFess(payload);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchGroupData1 = async () => {
    if (!groupData) {
      message.error("Please enter a Group ID");
      return;
    }

    try {
      const response = await api.get("/admin/fetchGroupDetails", {
        params: { internalGroupId: groupData },
      });

      if (response.data.success) {
        const fetchedMembers = response.data.data.members;

        if (Array.isArray(fetchedMembers) && fetchedMembers.length > 0) {
          const memberDetailsArray = fetchedMembers.map((member) => {
            return {
              _id: member._id,
              memberID: member.memberId,
              memberNo: member.memberNo,
              memberName: member.memberName,
              city: member.city,
              fees: 300,
              lateFees: member.lateFees || 0,
            };
          });

          setMembers(memberDetailsArray);
          setMemberDetails(memberDetailsArray);

          message.success("Data fetched successfully");
        } else {
          message.error("No members found in the group data.");
        }
      } else {
        message.error("Failed to fetch group data. Please try again.");
      }
    } catch (error) {
      console.error("API error:", error);
      message.error("Failed to fetch group data. Please try again.");
    }
  };

  const handleSubmit1 = async () => {
    await fetchGroupData1();
  };

  const handleDateChange = (date, field) => {
    const formattedDate = date ? moment(date).toISOString() : null;

    if (field === "CHEQUE") {
      setChequeDetails((prevDetails) => ({
        ...prevDetails,
        chequeDate: formattedDate, // Update cheque date in the correct format
      }));
    } else if (field === "RECEIPT") {
      // Update for receipt date
      setChequeDetails((prevDetails) => ({
        ...prevDetails,
        receiptDate: formattedDate, // Update receipt date in the correct format
      }));
    } else if (field === "CASH") {
      // Update for cash receipt date
      setCashDetails((prevDetails) => ({
        ...prevDetails,
        receiptDate: formattedDate, // Update cash receipt date in the correct format
      }));
    }
  };

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (!txnId) {
        message.error("Transaction ID is undefined");
        return;
      }

      try {
        setLoading(true);

        const response = await api.get(
          `admin/getTransactionDetails?txnId=${txnId}`
        );
        if (response.status === 200) {
          const transactionDetails = response.data;
          const dfcNumber = transactionDetails.paymentDetails.dfcNumber || "";
          setDfcNumber(dfcNumber);

          setPaymentMethod(transactionDetails.paymentDetails.paymentMethod);

          const members = transactionDetails.paymentDetails.members || [];
          const totalFees = members.reduce(
            (sum, member) => sum + (member.fees || 0) + (member.lateFees || 0),
            0
          );

          // Calculate amount (only fees without additional charges for now)
          const amount = totalFees; // Just set fees total as amount

          form.setFieldsValue({
            internalGroupId: transactionDetails.paymentDetails.internalGroupId,
            members: transactionDetails.paymentDetails.members.map(
              (member) => ({
                _id: member._id,
                memberNo: member.memberNo,
                memberName: `${member.firstname} ${member.middlename} ${member.lastname}`,
                city: member.city,
                fees: member.fees,
                lateFees: member.lateFees,
              })
            ),
            receiptNo: transactionDetails.paymentDetails.receiptNumber || "",
            amount: amount, // Only set the amount field, not combinedTotal
          });

          // Log the values being set, especially the amount
          console.log("Form values being set:", {
            amount: amount, // Only log amount
          });

          if (transactionDetails.paymentDetails.paymentMethod === "CHEQUE") {
            const chequeDetails =
              transactionDetails.paymentDetails.chequeDetails || {};
            setChequeDetails({
              bankName: chequeDetails.bankName || "",
              branchName: chequeDetails.branchName || "",
              chequeNo: chequeDetails.chequeNo || "",
              chequeDate: chequeDetails.chequeDate
                ? moment(chequeDetails.chequeDate)
                : null,
              bankCharges: chequeDetails.bankCharges || 0,
              chequeReturnCharge: chequeDetails.chequeReturnCharge || 0,
              receiptDate: chequeDetails.receiptDate
                ? moment(chequeDetails.receiptDate)
                : null,
            });
          } else if (
            transactionDetails.paymentDetails.paymentMethod === "CASH"
          ) {
            const cashDetails =
              transactionDetails.paymentDetails.cashDetails || {};
            setCashDetails({
              receiptNo: cashDetails.receiptNo || "",
              receiptDate: cashDetails.receiptDate
                ? moment(cashDetails.receiptDate)
                : null,
            });
          }

          setMembers(transactionDetails.paymentDetails.members || []);
        } else {
          message.error("Failed to fetch transaction details");
        }
      } catch (error) {
        message.error("An error occurred while fetching transaction details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [txnId, form]);

  const handleEditSubmit = async () => {
    try {
      const membersFees = members.map((member) => ({
        memberId: member.memberId || member._id,
        memberNo: member.memberNo,
        memberName: member.memberName,
        city: member.city,
        fees: member.fees || 0,
        lateFees: member.lateFees || 0,
        totalFee: (member.fees || 0) + (member.lateFees || 0),
      }));

      // Calculate members' total fees
      const membersTotalFees = membersFees.reduce(
        (total, member) => total + member.totalFee,
        0
      );

      const bankCharges =
        paymentMethod === "CHEQUE"
          ? parseFloat(chequeDetails?.bankCharges) || 0
          : 0;
      const chequeReturnCharges =
        paymentMethod === "CHEQUE"
          ? parseFloat(chequeDetails?.chequeReturnCharge) || 0
          : 0;

      // Calculate the final total amount
      const totalAmount = membersTotalFees + bankCharges + chequeReturnCharges;

      // Format the receiptDate and paymentDate using moment before sending it
      const formattedReceiptDate =
        cashDetails?.receiptDate || chequeDetails?.receiptDate
          ? moment(
              cashDetails?.receiptDate || chequeDetails?.receiptDate
            ).toISOString()
          : "";

      const payload = {
        txnId: txnId,
        groupId: groupData,
        amount: totalAmount, // Updated total amount
        paymentMethod: paymentMethod,
        userId: userId,
        dfcNumber: dfcNumber,
        membersFees: membersFees,
        receiptNumber: cashDetails?.receiptNo || chequeDetails?.receiptNumber,
        receiptDate: formattedReceiptDate, // Use the formatted receiptDate
        paymentDetails: {
          userId: userId,
          paymentUserIds: membersFees.map((member) => member.memberId),
          dfcNumber: dfcNumber,
          internalGroupId: groupData,
          members: membersFees,
          paymentDate: moment().toISOString(), // Set paymentDate in ISO format
          paymentMethod: paymentMethod,
          amount: totalAmount, // Updated total amount including charges
          ...(paymentMethod === "CHEQUE" && {
            chequeDetails: chequeDetails || {},
          }),
          ...(paymentMethod === "CASH" && { cashDetails: cashDetails || null }),
        },
      };

      const response = await api.put(
        `admin/editTransaction?txnId=${txnId}`,
        payload
      );

      if (response.status === 200) {
        message.success("Transaction updated successfully");
        navigate("/transactions");
      } else {
        message.error(`Failed to submit: ${response.data.message}`);
      }
    } catch (error) {
      message.error(
        `An error occurred while submitting the transaction details. ${error.message}`
      );
    }
  };

  return (
    <SiteLayout>
      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => window.history.back()}
              title="Edit Transaction Details"
            />
          </Col>
        </Row>
      </div>
      <Skeleton active loading={loading}>
        <div className="subscription-container center-content">
          <Row gutter={2}>
            <Col
              xxl={20}
              xl={20}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ margin: "0 auto" }}
            >
              <div
                style={{ padding: "30px" }}
                className="checkout-form-container gig-sure-card-wrapper"
              >
                <Form
                  scrollToFirstError={true}
                  onFinish={handleFormSubmit1}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  form={form}
                  name="dfc"
                  autoComplete="off"
                  ref={formRef}
                ></Form>
                <Box component="form" sx={{ mt: 3 }}>
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row gutter={10} align="middle">
                        <Col span={16}>
                          <Form.Item
                            label="DFC No"
                            name="dfcNumber"
                            rules={[
                              {
                                pattern: /^(?:\d*)$/,
                                message: "Please insert a valid DFC No",
                              },
                            ]}
                          >
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder={dfcNumber || "Enter DFC Number"} // Dynamically show DFC No in placeholder
                              value={dfcNumber}
                              onChange={(e) => setDfcNumber(e.target.value)} // Update dfcNumber state on input change
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Button
                            // type="primary"
                            variant="contained"
                            color="primary"
                            className="btn"
                            disabled={loading}
                            onClick={handleSubmit}
                            sx={{ width: "100%", py: 1, height: "40px" }}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Search"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {dfcDetails && (
                    <Box mt={1}>
                      <Typography variant="h6" mb={2}>
                        DFC Details:
                      </Typography>
                      {dfcDetails.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Member No</TableCell>
                                <TableCell>Member Name</TableCell>
                                <TableCell>Date of Death</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dfcDetails.map((detail, index) => (
                                <TableRow key={index}>
                                  <TableCell>{detail.memberNo}</TableCell>
                                  <TableCell>{detail.memberName}</TableCell>
                                  <TableCell>
                                    {new Date(
                                      detail.dateOfDeath
                                    ).toLocaleDateString()}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography variant="body2">
                          No details available for this DFC number.
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
                <Form form={form} layout="vertical">
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row gutter={10} align="middle">
                        <Col span={16}>
                          <Form.Item
                            label="Group #"
                            name="internalGroupId"
                            rules={[
                              {
                                pattern: /^(?:\d*)$/,
                                message: "Please insert a valid Group ID",
                              },
                            ]}
                          >
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder="Group ID"
                              value={groupData} // Keep track of the Group ID input
                              onChange={(e) => setGroupData(e.target.value)} // Update the Group ID state
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="btn"
                            onClick={handleSubmit1}
                            sx={{ width: "100%", py: 1, height: "40px" }}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {members.map((member, index) => (
                    <Row gutter={10} key={index}>
                      {/* Member Id */}
                      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          label="Member Id #"
                          className="field-with-prefix"
                        >
                          <Input
                            placeholder="Member Id"
                            disabled={true}
                            style={{ color: "#808080" }}
                            value={member.memberNo}
                          />
                        </Form.Item>
                      </Col>

                      {/* Member Name */}
                      <Col xl={8} lg={12} md={16} sm={24} xs={24}>
                        <Form.Item
                          label="Member #"
                          className="field-with-prefix"
                        >
                          <Input
                            placeholder="Member"
                            disabled={true}
                            style={{ color: "#808080" }}
                            value={member.memberName}
                          />
                        </Form.Item>
                      </Col>

                      {/* City */}
                      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item label="City #" className="field-with-prefix">
                          <Input
                            placeholder="City"
                            disabled={true}
                            style={{ color: "#808080" }}
                            value={member.city}
                          />
                        </Form.Item>
                      </Col>

                      {/* Fees (fixed to 300) */}
                      <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                        <Form.Item label="Fees #" className="field-with-prefix">
                          <Input
                            placeholder="Fees"
                            disabled={true} // Fees set to 300 by default
                            value={300} // Display fixed value of 300
                          />
                        </Form.Item>
                      </Col>

                      {/* Late Fees */}
                      <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                        <Form.Item
                          label="Late Fees"
                          className="field-with-prefix"
                        >
                          <Input
                            placeholder="Late Fees"
                            onChange={handleInputChange(index, "lateFees")}
                            value={member.lateFees || 0}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}

                  {/* Display Combined Total */}
                  <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                    <Form.Item label="Combined Total">
                      <Input
                        disabled={true} // Make it read-only
                        value={combinedTotal}
                        style={{ color: "#808080" }} // Optional styling
                        name="combinedTotal"
                      />
                    </Form.Item>
                  </Col>
                </Form>

                <Form layout="vertical">
                  {/* Payment Method Radio Buttons */}
                  <Row className="mt-1">
                    <Col span={24}>
                      <Title level={4}>Payment</Title>
                    </Col>
                  </Row>

                  <Form.Item
                    name="PaymentMethod"
                    label="Payment Method"
                    initialValue={paymentMethod}
                  >
                    <Radio.Group
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <Radio value="CASH">Cash</Radio>
                      <Radio value="CHEQUE">Cheque</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {/* Input for Cheque Payment */}
                  {paymentMethod === "CHEQUE" && (
                    <>
                      {/* First Row: 4 items */}
                      <Row gutter={10}>
                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Bank Name"
                            rules={[
                              {
                                required: true,
                                message: "Bank Name is required",
                              },
                            ]}
                          >
                            <Input
                              value={chequeDetails.bankName}
                              name="bankName"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Branch Name"
                            rules={[
                              {
                                required: true,
                                message: "Branch Name is required",
                              },
                            ]}
                          >
                            <Input
                              value={chequeDetails.branchName}
                              name="branchName"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Cheque Number"
                            rules={[
                              {
                                required: true,
                                message: "Cheque Number is required",
                              },
                            ]}
                          >
                            <Input
                              value={chequeDetails.chequeNo}
                              name="chequeNo"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Cheque Date"
                            rules={[
                              {
                                required: true,
                                message: "Cheque Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              value={
                                chequeDetails.chequeDate
                                  ? moment(
                                      chequeDetails.chequeDate,
                                      "YYYY-MM-DD"
                                    )
                                  : null
                              }
                              format="YYYY-MM-DD"
                              onChange={(date) =>
                                handleDateChange(date, "CHEQUE")
                              }
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* Second Row: Additional items */}
                      <Row gutter={10}>
                        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item label="Bank Charges">
                            <Input
                              value={chequeDetails.bankCharges}
                              name="bankCharges"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item label="Cheque Return Charge">
                            <Input
                              value={chequeDetails.chequeReturnCharge}
                              name="chequeReturnCharge"
                              onChange={handleChequeChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item label="Receipt Number">
                            <Input
                              value={form.getFieldValue("receiptNo") || "N/A"}
                              disabled
                            />
                          </Form.Item>
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                          <Form.Item
                            label="Receipt Date"
                            rules={[
                              {
                                required: true,
                                message: "Receipt Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder="Receipt date"
                              style={{ width: "100%" }}
                              format="YYYY-MM-DD"
                              value={
                                chequeDetails.receiptDate
                                  ? moment(
                                      chequeDetails.receiptDate,
                                      "YYYY-MM-DD"
                                    )
                                  : null
                              }
                              onChange={(date) =>
                                handleDateChange(date, "RECEIPT")
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}

                  {paymentMethod === "CASH" && (
                    <Row gutter={10}>
                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item label="Receipt Number">
                          <Input
                            value={form.getFieldValue("receiptNo") || "N/A"}
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="Receipt Date"
                          rules={[
                            {
                              required: true,
                              message: "Receipt Date is required",
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder="Receipt date"
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD" // Set the format you want for display
                            value={
                              cashDetails.receiptDate
                                ? moment(cashDetails.receiptDate).isValid()
                                  ? moment(cashDetails.receiptDate) // Ensure moment object is valid
                                  : null
                                : null
                            }
                            onChange={(date) => handleDateChange(date, "CASH")} // handle date change
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {/* Submit Button */}
                  <div style={{ textAlign: "left", marginTop: "20px" }}>
                    <Button type="primary" onClick={handleEditSubmit}>
                      Update Transaction
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Skeleton>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(TransactioEdit);
